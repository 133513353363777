import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CategoryMaster } from '../shared/AllModel';
import { createCategoryMasterURL, updateCategoryMasterURL, getAllCategoryMasterURL, getByCategoryMasterIdURL, fileUpload } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-categorymaster',
  templateUrl: './categorymaster.component.html',
  styleUrls: ['./categorymaster.component.css']
})
export class CategorymasterComponent implements OnInit {

  CategoryMasterForm: FormGroup;
  CategoryMasterModel = new CategoryMaster();
  isEdit: boolean = true;
  hide = true;
  filetoUpload: File = null;
  url='';

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'name','img','gst','status','Action', ];
  dataSource = new MatTableDataSource<CategoryMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.CategoryMasterForm = this.formBuilder.group({
      categoryName: new FormControl('', [Validators.required,Validators.pattern("[a-zA-Z][a-zA-Z ]+")]),
      categoryImage: new FormControl('', [Validators.required,]),
      description: new FormControl('', [Validators.required,]),
      categoryStatus: new FormControl('', [Validators.required,]),
      gst: new FormControl('', [Validators.required,]),

    })
    this.getAllCategory();
  }
  SaveCategory() {
    if (this.isEdit) {
     
        this.httpService.postRequest(createCategoryMasterURL, this.CategoryMasterModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
            this.getAllCategory();
            this.CategoryMasterForm.reset();
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
    }
    else {
      this.httpService.putRequest(updateCategoryMasterURL, this.CategoryMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllCategory();
          this.CategoryMasterForm.reset();
          this.CategoryMasterModel = new CategoryMaster();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }
  getAllCategory() {
    this.httpService.getRequest(getAllCategoryMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  getCategoryById(categoryId) {
    this.httpService.getRequest(getByCategoryMasterIdURL + "/" + categoryId).subscribe((data: any) => {
      this.CategoryMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    
    })
  }

  handleFileInput(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.CategoryMasterModel.categoryImage = data.path;
      }
      else {
        this.toastr.errorToastr(data.categoryImage, 'error!', { timeout: 500 });
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
