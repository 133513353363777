import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateAdapter, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CustomerOrderReportClass } from '../shared/AllModel';
import { CustomerOrderReportListURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-orderlist',
  templateUrl: './orderlist.component.html',
  styleUrls: ['./orderlist.component.css']
})
export class OrderlistComponent implements OnInit {

  customerOrderReportForm: FormGroup;
  customerOrderReportModel = new CustomerOrderReportClass();
  errormsg: string;
  errormsgDiv: boolean = false;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  
  displayedColumns: string[] = ['Sr.No','Date', 'Customer Name', 'Mobile', 'Order Status', 'Amount', 'Action'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {

    this.customerOrderReportForm = this.formBuilder.group({
      orderStatus: new FormControl(''),
    });
  }

  getCustomerOrderList()
  {
    this.httpService.postRequest(CustomerOrderReportListURL, this.customerOrderReportModel).subscribe((data: any) => {
      if (data.length != 0) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.errormsgDiv = false;

      }
      else {
        this.errormsg = "No Records Found";
        this.errormsgDiv = true;
        this.dataSource = null;

      }
    })
  }

  viewOrder(orderId) {
    sessionStorage.setItem("viewOrderId", orderId); 
    this.router.navigateByUrl("/layout/viewadminreport")
  }
}
