import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { viewOrder, orderAssignDetails } from '../shared/AllModel';
import { acceptStatus, getOrderById } from '../shared/AllURL';
import { DataSharingService } from '../shared/service/data-sharing.service';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-vieworder',
  templateUrl: './vieworder.component.html',
  styleUrls: ['./vieworder.component.css']
})
export class VieworderComponent implements OnInit {

  viewOrderModel = new viewOrder();
  orderAssignDetailsModel = new orderAssignDetails();
  orderProductList: any = [];
  ViewCustomerOrderList:any[]=[];
  orderList:any[] = [];
  productList:any[] = [];
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, public dataService: DataSharingService, private httpService: HttpmethodsService, private router: Router, ) {
  }

  ngOnInit() {
    
    this.viewOrder();
  }

  // viewOrder() {

  //   this.httpService.getRequest(getOrderById + "/" + sessionStorage.getItem("orderId")).subscribe((data: any) => {
  //     this.viewOrderModel = data;
  //     this.orderProductList = data.productMasterList;

  //   })
  // }

  viewOrder(){
    this.httpService.getRequest(getOrderById + "/" + Number(sessionStorage.getItem("orderId"))).subscribe((data:any)=>{
      this.ViewCustomerOrderList.push(data);
      this.viewOrderModel = data;
      this.orderList.push(this.ViewCustomerOrderList[0].orderMaster);
      this.productList = this.ViewCustomerOrderList[0].productMasterList;
      console.log(this.ViewCustomerOrderList[0]);
    })
  }


  acceptOrder(viewOrderModel) {
    this.orderAssignDetailsModel.customerId = viewOrderModel.orderMaster.customerMaster.customerId;
    this.orderAssignDetailsModel.customerMailId = viewOrderModel.orderMaster.customerMaster.customerMailId;
    this.orderAssignDetailsModel.customerMobNo = viewOrderModel.orderMaster.customerMaster.customerMobNo;
    this.orderAssignDetailsModel.orderId = viewOrderModel.orderMaster.orderId;
    this.orderAssignDetailsModel.orderStatus = "Accepted";
    this.httpService.putRequest(acceptStatus, this.orderAssignDetailsModel).subscribe((data: any) => {
      this.toastr.successToastr('Order Accept Successfully...!', 'Success!', { timeout: 500 });
      this.router.navigateByUrl("/layout/assignorder");

    });
  }

}
