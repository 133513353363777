import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MainURL } from '../../constant/configUrl';
import { BehaviorSubject, Observable } from 'rxjs';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class HttpmethodsService {

  baseUrl = MainURL.HostUrl;
  currentMessage = new BehaviorSubject(null);

  constructor(private http: HttpClient) { }


  postRequest(url, body) {

    return this.http.post(this.baseUrl + url, body)
  }
  getRequest(url) {
    return this.http.get(this.baseUrl + url);
  }

  putRequest(url, body) {
    return this.http.put(this.baseUrl + url, body)
  }

  deleteRequest(url: string) {
    return this.http.delete(this.baseUrl + url);
  }


  public fileUpload(url: string, formData: File) {

    const uploadfile: FormData = new FormData();

    uploadfile.append('uploadfiledata', formData);

    return this.http.post(this.baseUrl + url, uploadfile);
  }

  createOrder(url: any, order: any): Observable<any> {
    return this.http.post(this.baseUrl + url, {
      customerName: order.customerName,
      customerMailId: order.customerMailId,
      customerMobNo: order.customerMobNo,
      amount: order.amount,
      customerId: order.customerId
    }, httpOptions)
  }

}
