import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { BannerMaster } from '../shared/AllModel';
import { createBannerMasterURL, updateBannerMasterURL, getAllBannerMasterURL, getByIdBannerMasterURL, fileUpload } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-bannermaster',
  templateUrl: './bannermaster.component.html',
  styleUrls: ['./bannermaster.component.css']
})
export class BannermasterComponent implements OnInit {

  BannerMasterForm: FormGroup;
  BannerMasterModel = new BannerMaster();
  isEdit: boolean = true;
  hide = true;
  filetoUpload: File = null;
  url='';

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'name','img','sdate','edate','status','Action', ];
  dataSource = new MatTableDataSource<BannerMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.BannerMasterForm = this.formBuilder.group({
      bannerName: new FormControl('', [Validators.required,Validators.pattern("[a-zA-Z][a-zA-Z ]+")]),
      bannerPath: new FormControl('', [Validators.required,]),
      bannerType: new FormControl('', [Validators.required,Validators.pattern("[a-zA-Z][a-zA-Z ]+")]),
      bannerDescription: new FormControl('', [Validators.required,]),
      endDate: new FormControl('', [Validators.required,]),
      startDate: new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),
    })
    this.getAllBanner();
  }
  SaveBanner() {
    if (this.isEdit) {
     
        this.httpService.postRequest(createBannerMasterURL, this.BannerMasterModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
            this.getAllBanner();
            this.BannerMasterForm.reset();
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
    }
    else {
      this.httpService.putRequest(updateBannerMasterURL, this.BannerMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllBanner();
          this.BannerMasterForm.reset();
          this.BannerMasterModel = new BannerMaster();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }
  getAllBanner() {
    this.httpService.getRequest(getAllBannerMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  getBannerById(bannerId) {
    this.httpService.getRequest(getByIdBannerMasterURL + "/" + bannerId).subscribe((data: any) => {
      this.BannerMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    
    })
  }

  handleFileInput(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.BannerMasterModel.bannerPath = data.path;
      }
      else {
        this.toastr.errorToastr(data.bannerPath, 'error!', { timeout: 500 });
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
