export const MainURL = {
  

    //ZPLUS SERVER SERVER TESTING
    HostUrl: 'https://suvija.com:9001',

    // HostUrl: 'https://192.168.0.18:9001'
    imageUrl: '/assets'
  }
 

  