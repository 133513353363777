import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatPaginatorModule, MatSidenavModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatAutocompleteModule, MatSlideToggleModule, MatDatepickerModule, MatProgressSpinnerModule, MatNativeDateModule, MatRadioModule, MatCheckboxModule, MatLabel } from '@angular/material';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatDialogModule, MatIconModule, MatDividerModule, MatListModule, MatToolbarModule, MatMenuModule, MatCardModule,MatTableModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HttpClientModule } from '@angular/common/http'; 
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import * as $ from 'jquery';
import { AdminmasterComponent } from './adminmaster/adminmaster.component';
import { CountrymasterComponent } from './countrymaster/countrymaster.component';
import { StatemasterComponent } from './statemaster/statemaster.component';
import { CitymasterComponent } from './citymaster/citymaster.component';
import { AreamasterComponent } from './areamaster/areamaster.component';
import { PincodemasterComponent } from './pincodemaster/pincodemaster.component';
import { CategorymasterComponent } from './categorymaster/categorymaster.component';
import { ProductmasterComponent } from './productmaster/productmaster.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { VendormasterComponent } from './vendormaster/vendormaster.component';
import { BannermasterComponent } from './bannermaster/bannermaster.component';
import { PlaceorderComponent } from './placeorder/placeorder.component';
import { VieworderplacedComponent } from './vieworderplaced/vieworderplaced.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PlacedorderlistComponent } from './placedorderlist/placedorderlist.component';
import { OrderhistoryComponent } from './orderhistory/orderhistory.component';
import { VieworderComponent } from './vieworder/vieworder.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DeliverymasterComponent } from './deliverymaster/deliverymaster.component';
import { PincodechargesmasterComponent } from './pincodechargesmaster/pincodechargesmaster.component';
import { AboutmasterComponent } from './aboutmaster/aboutmaster.component';
import { AssignorderComponent } from './assignorder/assignorder.component';
import { AllcustomerlistComponent } from './allcustomerlist/allcustomerlist.component';
import { OrderlistComponent } from './orderlist/orderlist.component';
import { DispatchorderComponent } from './dispatchorder/dispatchorder.component';
import { DeliverdorderComponent } from './deliverdorder/deliverdorder.component';
import { DailyreportComponent } from './dailyreport/dailyreport.component';
import { WeeklyorderreportComponent } from './weeklyorderreport/weeklyorderreport.component';
import { MonthlyorderreportComponent } from './monthlyorderreport/monthlyorderreport.component';
import { YearlyorderreportComponent } from './yearlyorderreport/yearlyorderreport.component';
import { MessagemasterComponent } from './messagemaster/messagemaster.component';




@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    DashboardComponent,
    AdminmasterComponent,
    CountrymasterComponent,
    StatemasterComponent,
    CitymasterComponent,
    AreamasterComponent,
    PincodemasterComponent,
    CategorymasterComponent,
    ProductmasterComponent,
    VendormasterComponent,
    BannermasterComponent,
    PlaceorderComponent,
    VieworderplacedComponent,
    PlacedorderlistComponent,
    OrderhistoryComponent,
    VieworderComponent,
    DeliverymasterComponent,
    PincodechargesmasterComponent,
    AboutmasterComponent,
    AssignorderComponent,
    AllcustomerlistComponent,
    OrderlistComponent,
    DispatchorderComponent,
    DeliverdorderComponent,
    DailyreportComponent,
    WeeklyorderreportComponent,
    MonthlyorderreportComponent,
    YearlyorderreportComponent,
    MessagemasterComponent,
     
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    MatButtonModule, MatDialogModule,MatIconModule,MatDividerModule,MatListModule,MatToolbarModule,
    MatMenuModule,MatCardModule, MatPaginatorModule,
    CommonModule,
    RouterModule, 
    SharedModule,
    MatSidenavModule,
    MatDividerModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDialogModule, 
    MatIconModule,
    MatSidenavModule,
    MatDividerModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatPaginatorModule,
    HttpClientModule,
    MatTableModule,
    AngularEditorModule,
    MatDialogModule,
    NgxSpinnerModule,
    BsDatepickerModule.forRoot(),

    CarouselModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},DatePipe],
  exports: [
    MatButtonModule, MatDialogModule, MatIconModule],
    
  bootstrap: [AppComponent],
  entryComponents: [],

})
export class AppModule { }
