import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AreaMaster, CityMaster, CountryMaster, DeliveryBoyMaster, PincodeMaster, StateMaster } from '../shared/AllModel';
import { getActiveCountryMasterURL, getCountryWiseStateActiveListURL, getStateWiseCityMasterListURL, getAllCityIdWiseAreaMasterActiveListURL, getAllAreaIdWisePincodeMasterURL, getActivePinCodeURL, createDeliveryMasterURL, updateDeliveryMasterURL, fileUpload } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-deliverymaster',
  templateUrl: './deliverymaster.component.html',
  styleUrls: ['./deliverymaster.component.css']
})
export class DeliverymasterComponent implements OnInit {

  DeliveryMasterForm: FormGroup;
  DeliveryMasterModel = new DeliveryBoyMaster();
  isEdit: boolean = true;
  hide = true;
  mobNumberMsg: string;
  filetoUpload: File;
  PincodeMasterModel = new PincodeMaster();
  AreaMasterModel = new AreaMaster();
  CountryMasterModel = new CountryMaster();
  StateMasterModel = new StateMaster();
  CityMasterModel = new CityMaster();
  ActiveCountryList: Array<CountryMaster>;
  ActiveStateList: Array<StateMaster>;
  ActiveCityList: Array<CityMaster>;
  ActiveAreaList: Array<AreaMaster>;
  ActivePincodeList:Array<PincodeMaster>;

 
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'name', 'mob', 'email', 'address', 'status', ];
  dataSource = new MatTableDataSource<DeliveryBoyMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.DeliveryMasterForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z][a-zA-Z ]+")]),
      middleName: new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z][a-zA-Z ]+")]),
      lastName: new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z][a-zA-Z ]+")]),
      mobileNo: new FormControl('', [Validators.required, Validators.pattern("[6-9]\\d{9}")]),
      emailId: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]),
      status: new FormControl('', [Validators.required,]),
      pincodeId: new FormControl('', [Validators.required,]),
      adharCard: new FormControl('', [Validators.required,]),
      panCard: new FormControl('', [Validators.required,]),
      profilePic: new FormControl('', [Validators.required,]),
      password: new FormControl('', [Validators.required,]),
      registrationDate: new FormControl('', [Validators.required,]),
      stateId:new FormControl('', [Validators.required,]),
      countryId:new FormControl('', [Validators.required,]),
      cityId:new FormControl('', [Validators.required,]),
      areaId:new FormControl('', [Validators.required,]),
      drivingLiscence:new FormControl('', [Validators.required,]),
 

    })
    this.ActiveCountry();

  }

  
  ActiveCountry() {
    this.httpService.getRequest(getActiveCountryMasterURL).subscribe((data: any) => {
      this.ActiveCountryList = data;
    })
  }

  ActiveState(countryId) {
    this.httpService.getRequest(getCountryWiseStateActiveListURL + "/" + countryId).subscribe((data: any) => {
      this.ActiveStateList = data;
    })
  }

  ActiveCity(stateId) {
    this.httpService.getRequest(getStateWiseCityMasterListURL + "/" + stateId).subscribe((data: any) => {
      this.ActiveCityList = data;
    })
  }

  ActiveArea(cityId) {
    this.httpService.getRequest(getAllCityIdWiseAreaMasterActiveListURL + "/" + cityId).subscribe((data: any) => {
      this.ActiveAreaList = data;
    })
  }

  ActivePincode(areaId){
    this.httpService.getRequest(getActivePinCodeURL + "?areaIdString="  + areaId).subscribe((data: any) => {
      this.ActivePincodeList = data;
    })
  }

  addDeliveryBoy() {
    if (this.isEdit) {
      this.DeliveryMasterModel.adminId = 1;
      this.httpService.postRequest(createDeliveryMasterURL, this.DeliveryMasterModel).subscribe((data: any) => {
        this.isEdit = true;
        this.ActiveCountry();
        // this.getAllDelivery();
        this.DeliveryMasterForm.reset();
        this.toastr.successToastr('Save Successfully...!', 'Success!', {timeout : 500});
      })
    }
    else {
      this.DeliveryMasterModel.adminId = 1;
      this.httpService.putRequest(updateDeliveryMasterURL, this.DeliveryMasterModel).subscribe((data: any) => {
        this.isEdit = true;
        this.ActiveCountry();
        // this.getAllDelivery();
        this.DeliveryMasterForm.reset();
        this.toastr.successToastr('Update Successfully...!', 'Success!', {timeout : 500});
      })
    }
  }

  handleFileInput(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload, this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.DeliveryMasterModel.adharCard = data.path;
      }
      else {
        this.toastr.errorToastr(data.adharCard, 'error!', { timeout: 500 });
      }
    })
  }
  handleFileInput1(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload, this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.DeliveryMasterModel.panCard = data.path;
      }
      else {
        this.toastr.errorToastr(data.panCard, 'error!', { timeout: 500 });
      }
    })
  }
  handleFileInput2(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload, this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.DeliveryMasterModel.profilePic = data.path;
      }
      else {
        this.toastr.errorToastr(data.profilePic, 'error!', { timeout: 500 });
      }
    })
  }
  handleFileInput3(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload, this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.DeliveryMasterModel.drivingLiscence = data.path;
      }
      else {
        this.toastr.errorToastr(data.drivingLiscence, 'error!', { timeout: 500 });
      }
    })
  }
}
