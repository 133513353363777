import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AreaMaster, CityMaster, CountryMaster, PincodeMaster, StateMaster, VendorMaster } from '../shared/AllModel';
import { createVendorURL, fileUpload, getActiveCountryMasterURL, getAllAreaIdWisePincodeMasterURL, getAllCityIdWiseAreaMasterActiveListURL, getAllVendorURL, getByVendorIdURL, getCountryWiseStateActiveListURL, getStateWiseCityMasterListURL, updateVendorURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-vendormaster',
  templateUrl: './vendormaster.component.html',
  styleUrls: ['./vendormaster.component.css']
})
export class VendormasterComponent implements OnInit {

  VendorMasterForm: FormGroup;
  VendorMasterModel = new VendorMaster();
  isEdit: boolean = true;
  hide = true;
  mobNumberMsg: string;
  filetoUpload: File;
  PincodeMasterModel = new PincodeMaster();
  AreaMasterModel = new AreaMaster();
  CountryMasterModel = new CountryMaster();
  StateMasterModel = new StateMaster();
  CityMasterModel = new CityMaster();
  ActiveCountryList: Array<CountryMaster>;
  ActiveStateList: Array<StateMaster>;
  ActiveCityList: Array<CityMaster>;
  ActiveAreaList: Array<AreaMaster>;
  ActivePincodeList:Array<PincodeMaster>;


  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'name','no', 'mob', 'email', 'address', 'status','Action', ];
  dataSource = new MatTableDataSource<VendorMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.VendorMasterForm = this.formBuilder.group({
      vendorFirstName: new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z][a-zA-Z ]+")]),
      vendorMiddleName: new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z][a-zA-Z ]+")]),
      vendorLastName: new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z][a-zA-Z ]+")]),
      vendorMobile: new FormControl('', [Validators.required, Validators.pattern("[6-9]\\d{9}")]),
      vendorEmail: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]),
      vendorStatus: new FormControl('', [Validators.required,]),
      pincodeId: new FormControl('', [Validators.required,]),
      vendorAadharCard: new FormControl('', [Validators.required,]),
      vendorPAN: new FormControl('', [Validators.required,]),
      vendorPhoto: new FormControl('', [Validators.required,]),
      password: new FormControl('', [Validators.required,]),
      vendorRegDate: new FormControl('', [Validators.required,]),
      stateId:new FormControl('', [Validators.required,]),
      countryId:new FormControl('', [Validators.required,]),
      cityId:new FormControl('', [Validators.required,]),
      areaId:new FormControl('', [Validators.required,]),
      communicationAddress:new FormControl('', [Validators.required,]),
      permanentAddress:new FormControl('', [Validators.required,]),

    })
    this.getAllVendor();

    this.ActiveCountry();

  }

  ActiveCountry() {
    this.httpService.getRequest(getActiveCountryMasterURL).subscribe((data: any) => {
      this.ActiveCountryList = data;
    })
  }

  ActiveState(countryId) {
    this.httpService.getRequest(getCountryWiseStateActiveListURL + "/" + countryId).subscribe((data: any) => {
      this.ActiveStateList = data;
    })
  }

  ActiveCity(stateId) {
    this.httpService.getRequest(getStateWiseCityMasterListURL + "/" + stateId).subscribe((data: any) => {
      this.ActiveCityList = data;
    })
  }

  ActiveArea(cityId) {
    this.httpService.getRequest(getAllCityIdWiseAreaMasterActiveListURL + "/" + cityId).subscribe((data: any) => {
      this.ActiveAreaList = data;
    })
  }

  ActivePincode(areaId){
    this.httpService.getRequest(getAllAreaIdWisePincodeMasterURL + "/" + areaId).subscribe((data: any) => {
      this.ActivePincodeList = data;
    })
  }

  SaveVendor() {
    this.VendorMasterModel.adminId = 1;
    if (this.isEdit) {
      this.httpService.postRequest(createVendorURL, this.VendorMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllVendor();
          this.VendorMasterForm.reset();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
    }
    else {
      this.httpService.putRequest(updateVendorURL, this.VendorMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllVendor();
          this.VendorMasterForm.reset();
          this.VendorMasterModel = new VendorMaster();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  handleFileInput(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload, this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.VendorMasterModel.vendorAadharCard = data.path;
      }
      else {
        this.toastr.errorToastr(data.vendorAadharCard, 'error!', { timeout: 500 });
      }
    })
  }
  handleFileInput1(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload, this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.VendorMasterModel.vendorPAN = data.path;
      }
      else {
        this.toastr.errorToastr(data.vendorPAN, 'error!', { timeout: 500 });
      }
    })
  }
  handleFileInput2(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload, this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.VendorMasterModel.vendorPhoto = data.path;
      }
      else {
        this.toastr.errorToastr(data.vendorPhoto, 'error!', { timeout: 500 });
      }
    })
  }

  getAllVendor() {
    this.httpService.getRequest(getAllVendorURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  getVendorById(vendorId) {
    this.httpService.getRequest(getByVendorIdURL + "/" + vendorId).subscribe((data: any) => {
      this.VendorMasterModel = data;
      this.isEdit = false;
      this.ActiveState(data.countryId);
      this.ActiveCity(data.stateId);
      this.ActiveArea(data.cityId);
      this.ActivePincode(data.areaId);
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();

    })
  }

}
