import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AboutMaster } from '../shared/AllModel';
import { createAboutMasterURL, updateAboutMasterURL, getAllAboutMasterURL, getByAboutMasterIdURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-aboutmaster',
  templateUrl: './aboutmaster.component.html',
  styleUrls: ['./aboutmaster.component.css']
})
export class AboutmasterComponent implements OnInit {

  AboutMasterForm: FormGroup;
  AboutMasterModel = new AboutMaster();
  isEdit: boolean = true;
  hide = true;
  mobNumberMsg :string;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter About Us Description here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
 
 
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  config1: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter Vision Description here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
 
 
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  config2: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter Mission Description here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
 
 
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }



  ngOnInit() {
    this.AboutMasterForm = this.formBuilder.group({
      aboutUsDescription: new FormControl('', [Validators.required,]),
      missionDescription: new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),
      visionDescription: new FormControl('', [Validators.required,]),
    
    })
    this.getAllAbout();
  }

  SaveAbout() {
    if (this.isEdit) {
        this.httpService.postRequest(createAboutMasterURL, this.AboutMasterModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
            this.getAllAbout();
            this.AboutMasterForm.reset();
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
    }
    else {
      this.httpService.putRequest(updateAboutMasterURL, this.AboutMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllAbout();
          this.AboutMasterForm.reset();
          this.AboutMasterModel = new AboutMaster();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  getAllAbout() {
    this.httpService.getRequest(getAllAboutMasterURL).subscribe((data: any) => {
      if (data) {
        this.AboutMasterModel.aboutUsId = data[0].aboutUsId;
        this.getAboutById(this.AboutMasterModel.aboutUsId);
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  getAboutById(aboutUsId) {
    this.httpService.getRequest(getByAboutMasterIdURL + "/" + aboutUsId).subscribe((data: any) => {
      this.AboutMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    
    })
  }


}
