import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  vendorFlag:boolean=false;
  adminFlag:boolean=false;


  constructor() { }

  ngOnInit() {
    $(document).ready(function(){
      $('ul li a').click(function(){
        $('li a').removeClass("active");
        $(this).addClass("active");
    });
    });

    if(sessionStorage.getItem("vendorId")){
      this.vendorFlag=true;
      this.adminFlag=false;
    }
    else{
      this.vendorFlag=false;
      this.adminFlag=true;
    }
    
  }

}
