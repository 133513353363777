import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AreaMaster, CityMaster, CountryMaster, StateMaster } from '../shared/AllModel';
import { createAreaMasterURL, getActiveCountryMasterURL, getAllAreaMasterURL, getByAreaMasterIdURL, getCountryWiseStateActiveListURL, getStateWiseCityMasterListURL, updateAreaMasterURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-areamaster',
  templateUrl: './areamaster.component.html',
  styleUrls: ['./areamaster.component.css']
})
export class AreamasterComponent implements OnInit {

  AreaMasterForm: FormGroup;
  AreaMasterModel = new AreaMaster();

  isEdit: boolean = true;
  hide = true;
  mobNumberMsg :string;
  ActiveCountryList:Array<CountryMaster>;
  ActiveStateList:Array<StateMaster >;
  ActiveCityList:Array<CityMaster>;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'name','status','Action', ];
  dataSource = new MatTableDataSource<CityMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  
  ngOnInit() {

    this.AreaMasterForm = this.formBuilder.group({
      areaName: new FormControl('', [Validators.required,Validators.pattern("[a-zA-Z][a-zA-Z ]+")]),
      areaStatus: new FormControl('', [Validators.required,]),
      stateId: new FormControl('', [Validators.required,]),
      countryId:new FormControl('', [Validators.required,]),
      cityId :new FormControl('', [Validators.required,]),
    })
    this.ActiveCountry();
    this.getAllArea();
    
  }

  ActiveCountry(){
    this.httpService.getRequest(getActiveCountryMasterURL).subscribe((data: any) => {
     this.ActiveCountryList=data;
    })
  }

  ActiveState(countryId){
    this.httpService.getRequest(getCountryWiseStateActiveListURL + "/" + countryId).subscribe((data: any) => {
      this.ActiveStateList=data;
     })
  }

  ActiveCity(stateId){
    this.httpService.getRequest(getStateWiseCityMasterListURL + "/" + stateId).subscribe((data: any) => {
      this.ActiveCityList=data;
     })
  }

  SaveArea() {
    if (this.isEdit) {
        this.httpService.postRequest(createAreaMasterURL, this.AreaMasterModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
            this.getAllArea();
            this.AreaMasterForm.reset();
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
    }
    else {
      this.httpService.putRequest(updateAreaMasterURL, this.AreaMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllArea();
          this.AreaMasterForm.reset();
          this.AreaMasterModel = new AreaMaster();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  getAllArea() {
    this.httpService.getRequest(getAllAreaMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  getAreaById(areaId) {
    this.httpService.getRequest(getByAreaMasterIdURL + "/" + areaId).subscribe((data: any) => {
      this.AreaMasterModel = data;
      this.AreaMasterModel.countryId = data.countryId;
      this.AreaMasterModel.stateId = data.stateId;
      this.AreaMasterModel.cityId = data.cityId;
      this.ActiveState(this.AreaMasterModel.countryId);
      this.ActiveCity(this.AreaMasterModel.stateId);
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    
    })
  }

}
