


//admin login//////
export const createAdminURL="/admin_master";
export const updateAdminURL="/admin_master";
export const getAllAdminURL="/admin_master/getAllAdminMaster";
export const getByAdminIdURL="/admin_master/getByAdminId";
export const adminLoginURL="/admin_master/adminLogin";
export const CustomerOrderReportListURL = '/admin_master/getStatusAndTypeWiseOrderReport';

/////country master/////
export const createCountryMasterURL="/country_master/createCountry";
export const updateCountryMasterURL="/country_master/updateCountry";
export const getAllCountryMasterURL="/country_master/getAllCountrys";
export const getByCountryMasterIdURL="/country_master/getByCountryId";
export const getActiveCountryMasterURL="/country_master/getAllCountryStatus";

///state master///
export const createStateMasterURL="/state";
export const updateStateMasterURL="/state";
export const getAllStateMasterURL="/state";
export const getByIdStateMasterURL="/state";
export const getActiveStateMasterURL="/state/activeState";
export const getCountryWiseStateActiveListURL="/state/getAllCountryIdWiseStateMasterActiveList";

////city master///
export const createCityMasterURL="/city";
export const updateCityMasterURL="/city";
export const getAllCityMasterURL="/city";
export const getByCityMasterIdURL="/city";
export const getActiveCityMasterURL="/city/getActiveList";
export const getStateWiseCityMasterListURL="/city/getAllStateIdWiseCityMasterActiveList";

////Area master////
export const createAreaMasterURL="/area";
export const updateAreaMasterURL="/area";
export const getAllAreaMasterURL="/area";
export const getByAreaMasterIdURL="/area/getByAreaIdWiseAreaMasterList";
export const getActiveAreaMasterURL="/area/getActiveListOfAreaMAster";
export const getAllCityIdWiseAreaMasterActiveListURL="/area/getAllCityIdWiseAreaMasterActiveList";

///pincode master///
export const createPincodeMasterURL="/pincode";
export const updatePincodeMasterURL="/pincode";
export const getAllPincodeMasterURL="/pincode";
export const getByPincodeMasterIdURL="/pincode/getPincodeId";
export const getActivePincodeMasterURL="/pincode/getActiveList";
export const getAllAreaIdWisePincodeMasterURL="/pincode/getAllAreaIdWisePincodeMasterActiveList";
export const getActivePinCodeURL="/pincode/active/areaList";

////category master///
export const createCategoryMasterURL="/category_master";
export const updateCategoryMasterURL="/category_master";
export const getAllCategoryMasterURL="/category_master";
export const getActiveCategoryMasterURL="/category_master/getActiveList";
export const getByCategoryMasterIdURL="/category_master/editCategoryMaster";
export const fileUpload="/uploadfile";

////product master//
export const createProductURL="/product_master";
export const updateProductURL="/product_master";
export const getAllProductURL="/product_master";
export const getByProductURL="/product_master/editProductMaster";
export const getRandomProductListURL="/product_master/getRamdomProductList/random";
export const getCustomerIdWiseProductDetails="/product_master/getCustomerIdWiseProductDetails"


///vendor master//
export const createVendorURL="/vendor_master/CreateVendor";
export const updateVendorURL="/vendor_master/UpdateVendor";
export const getAllVendorURL="/vendor_master/getAllVendor";
export const getByVendorIdURL="/vendor_master/getVendorById";
export const getActiveVendorURL="/vendor_master/getActiveVendor";


///////Banner master/////
export const createBannerMasterURL="/banner_master";
export const updateBannerMasterURL="/banner_master";
export const getAllBannerMasterURL="/banner_master/getAllBannerMasterList";
export const getByIdBannerMasterURL="/banner_master/editBannerMaster";
export const getActiveBannerMasterURL="/banner_master/getActiveList";

////customer master//
export const createCustomerMasterURL="/customer_master";
export const updateCustomerMasterURL="/customer_master";
export const getAllCustomerMasterURL="/customer_master/getAllCustomerMasterList";
export const getActiveCustomerMasterURL="/customer_master/getActiveList";
export const getByCustomerMasterIdURL="/customer_master/editCustomerMaster";
export const customerLoginURL="/customer_master/customerLogin";
export const checkCustomerMobileNumberURL="/customer_master/checkCustomerMobNo";
export const checkPasswordURL="/customer_master/customer/checkPassword";
export const updatePassowrdURL="/customer_master/updatePassword";
export const userRegistrationOTPURL="/customer_master/verifyOtp";


//wishlist/////
export const saveToWishListURL="/wish_list_master";
export const deleteWishListDataURL="/wish_list_master/delete";
export const getAllWishListURL="/wish_list_master/getCustomerIdWiseWishList";
export const getWishlistCountURL="/wish_list_master/getCustomerIdWiseWishListCount";

///addtocart/////
export const SaveCartURL="/cart_controller";
export const getCarListURL="/cart_controller/getCustomerIdWiseCartList";
export const plusCartQouantityURL="/cart_controller/plusCartQty";
export const minusCartQuantityURL="/cart_controller/minusCartQty";
export const getCartCountURL="/cart_controller/getCountCustomerIdWise";
export const deleteCartURL="/cart_controller/deleteCart";

///////product master////////////
export const createProductMasterURL="/product_master";
export const updateProductMasterURL="/product_master";
export const getProductMasterURL="/product_master";
export const getByIdProductMasterURL="/product_master/editProductMaster";   

/////recentaly product///////////
export const createRecentalyViewProductURL="/recentlyviewproduct/saveRecentlyViewProduct";
export const getRecentalyViewProductURL="/recentlyviewproduct/getCustomerIdAndProductIdWiseOrderDataDes";
export const getSimilarProductListURL="/product_master/getCustomerIdWiseProductDetails";

////address master///////
export const SaveAddressURL="/address/saveAddress";
export const editCustomerAddressURL="/address/editAddress"
export const getAllAddressURL="/address/getAddressMaster";
export const makeDefaultAddressURL="/address/makeDefaultAddress";
export const deleteCustomerAddressURL="/address/deleteAddress";
export const updateAddressURL="/address/updateAddress";
export const getAllCityIdWiseAreaMasterActiveList="/area/getAllCityIdWiseAreaMasterActiveList";
export const getAllStateIdWiseCityMasterActiveListMasterURL="/city/getAllStateIdWiseCityMasterActiveList";
export const getActiveStateURL="/state/activeState";
export const getAllAreaIdWisePincodeMasterActiveListMasterURL="/pincode/getAllAreaIdWisePincodeMasterActiveList"

// =========== Razor pay =============== //

export const createPayment = "/createPayment"

///////order master//////////
export const saveOrderURL="/order"; 
export const getOrderById = '/order/getOrder';  
export const getOrderPlacedListUrl = "/order/getPlacedOrder"
export const getOrderDetailsByOrderID = "/order/getOrderIdWiseCustomerMasterAndProductList"
export const updateOrderStatus = "/order/updateStatus";
export const updateOrderStatusURL="/order/orderno";
export const acceptStatus = "/order/acceptorreject"
export const getCustomerOrderListURL="/order/getOrderList";
export const ViewOrderByOrderIdURL="/order/getOrderIdWiseCustomerMasterAndProductList";
export const getOrderTrackListURL="/order_track/getOrderTrackList";
export const orderHistoryURL = '/order/getOrderByStatus';
export const orderUpdateStatus="/order/orderUpdateStatus";
export const dashBordTodayAndWeeklyCntURL="/order/dashBordTodayAndWeeklyCnt";


////////jasper/////////
export const orderInvoiceJasperURL="/jasper/customerYourOrderInvoice";


////vendor master////
export const ActiveVendorURL = '/vendor_master/getActiveVendor';
export const AssignOrderURL = '/order/assign';
export const vendorLoginURL="/vendor_master/VendorLogin";

///delivery master////
export const createDeliveryMasterURL='/DeliveryBoy/createDeliveryBoy';
export const updateDeliveryMasterURL="/DeliveryBoy/updateDeliveryBoy";
export const getAllDeliveryMasterURL="/DeliveryBoy/getAllDeliveryBoy";
export const getByDeliveryMasterURL="/DeliveryBoy/getDeliveryBoyProfile";
export const getActiveDeliveryMasterURL="/DeliveryBoy/getActiveDeliveryBoys";

///delivery charges master///
export const createPinCodeChargesMasterURL="/pincode_charges_master";
export const updatePinCodeChargesMasterURL="/pincode_charges_master";
export const getAllPinCodeChargesMasterURL ="/pincode_charges_master";
export const getByPinCodeChargesMasterURL="/pincode_charges_master/getPinCodeChargesId";
export const getPinCodeCodeWiseChargesURL="/pincode_charges_master/getPinCodeCodeWiseCharges";

////about master///
export const createAboutMasterURL="/about_us_master";
export const updateAboutMasterURL="/about_us_master";
export const getAllAboutMasterURL="/about_us_master/getAllAboutUs";
export const getActiveAboutMasterURL="/about_us_master/activeAboutUs";
export const getByAboutMasterIdURL="/about_us_master/getByAboutUsId";


/////customer reviews///
export const createCustomerReviewsMasterURL="/customer_review_master/create";
export const updateCustomerReviewsMasterURL="/customer_review_master/update";
export const getAllCustomerReviewsMasterURL="/customer_review_master";
export const getByCustomerReviewsMasterIdURL="/customer_review_master/editCustomerReviewMaster";
export const getCustomerReviewsByProductIdURL="/customer_review_master/getProductIdWiseCustomerReviewMaster";
export const getProductWiseRandomProductListURL="/customer_review_master/getProductWiseRandomProductList/random";


//////Report api//////
export const getOrderListDailyURL="/order/getOrderListDaily";
export const getOrderListWeeklyURL="/order/getOrderListWeekly";
export const getOrderListMontlyURL="/order/getOrderListMontly";
export const getOrderListYearlyURL="/order/getOrderListYearly";

////message master//////
export const createMessageMasterURL="/MessageMaster/createMessage";
export const updateMessageMasterURL="/MessageMaster/updateMessage";
export const getAllMessageMasterURL="/MessageMaster/getAllMessage";
export const getActiveMessageMasterURL="/MessageMaster/getActiveList";
export const getByMessageMasterIdURL="/MessageMaster/getByMessageId";

//contact us////
export const createContactUsURL="/contact";
export const updateContactUsURL="/contact";
export const getAllContactUsURL="/contact/getAllContact";
export const getByIdContactUsURL="/contact/getByContactId";