import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AreaMaster, CountryMaster, StateMaster, CityMaster, PincodeMaster } from '../shared/AllModel';
import { createPincodeMasterURL, getActiveCountryMasterURL, getAllCityIdWiseAreaMasterActiveListURL, getAllPincodeMasterURL, getByAreaMasterIdURL, getByPincodeMasterIdURL, getCountryWiseStateActiveListURL, getStateWiseCityMasterListURL, updatePincodeMasterURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-pincodemaster',
  templateUrl: './pincodemaster.component.html',
  styleUrls: ['./pincodemaster.component.css']
})
export class PincodemasterComponent implements OnInit {

  PincodeMasterForm: FormGroup;
  PincodeMasterModel = new PincodeMaster();

  isEdit: boolean = true;
  hide = true;
  mobNumberMsg :string;
  ActiveCountryList:Array<CountryMaster>;
  ActiveStateList:Array<StateMaster >;
  ActiveCityList:Array<CityMaster>;
  ActiveAreaList:Array<AreaMaster>;



  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'name','status','Action', ];
  dataSource = new MatTableDataSource<CityMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.PincodeMasterForm = this.formBuilder.group({
      pincodeCode: new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),
      stateId: new FormControl('', [Validators.required,]),
      countryId:new FormControl('', [Validators.required,]),
      cityId :new FormControl('', [Validators.required,]),
      areaId :new FormControl('', [Validators.required,]),

    })
    this.ActiveCountry();
    this.getAllPincode();
  }

  ActiveCountry(){
    this.httpService.getRequest(getActiveCountryMasterURL).subscribe((data: any) => {
     this.ActiveCountryList=data;
    })
  }

  ActiveState(countryId){
    this.httpService.getRequest(getCountryWiseStateActiveListURL + "/" + countryId).subscribe((data: any) => {
      this.ActiveStateList=data;
     })
  }

  ActiveCity(stateId){
    this.httpService.getRequest(getStateWiseCityMasterListURL + "/" + stateId).subscribe((data: any) => {
      this.ActiveCityList=data;
     })
  }

  ActiveArea(cityId){
    this.httpService.getRequest(getAllCityIdWiseAreaMasterActiveListURL + "/" + cityId).subscribe((data: any) => {
      this.ActiveAreaList=data;
     })
  }

  SavePincode() {
    if (this.isEdit) {
        this.httpService.postRequest(createPincodeMasterURL, this.PincodeMasterModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
            this.getAllPincode();
            this.PincodeMasterForm.reset();
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
    }
    else {
      this.httpService.putRequest(updatePincodeMasterURL, this.PincodeMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllPincode();
          this.PincodeMasterForm.reset();
          this.PincodeMasterModel = new PincodeMaster();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  getAllPincode() {
    this.httpService.getRequest(getAllPincodeMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }


  getPincodeById(pincodeId) {
    this.httpService.getRequest(getByPincodeMasterIdURL + "/" + pincodeId).subscribe((data: any) => {
      this.PincodeMasterModel = data;
      this.PincodeMasterModel.countryId = data.countryId;
      this.PincodeMasterModel.stateId = data.stateId;
      this.PincodeMasterModel.cityId = data.cityId; 
      this.PincodeMasterModel.areaId = data.areaId; 
      this.ActiveState(this.PincodeMasterModel.countryId);
      this.ActiveCity(this.PincodeMasterModel.stateId);
      this.ActiveArea(this.PincodeMasterModel.cityId); 
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    
    })
  }
}
