import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { customerOrder } from '../shared/AllModel';
import { getOrderPlacedListUrl } from '../shared/AllURL';
import { DataSharingService } from '../shared/service/data-sharing.service';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-placeorder',
  templateUrl: './placeorder.component.html',
  styleUrls: ['./placeorder.component.css']
})
export class PlaceorderComponent implements OnInit {


  constructor(private formBuilder: FormBuilder,public dataService: DataSharingService, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'orderno','date','name','mob','finalamt','status','Action'];
  dataSource = new MatTableDataSource<customerOrder>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.getAllOrder();
  }

  getAllOrder() {
    this.httpService.getRequest(getOrderPlacedListUrl).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  
  viewOrder(order) {
    this.dataService.data = order;
    sessionStorage.setItem("orderId", order);
    this.router.navigateByUrl("/layout/vieworderplaced");
  }
}
