import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  vendorFlag:boolean=false;
  adminFlag:boolean=false;

  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  constructor(public router: Router) { }

  ngOnInit() {

    if(sessionStorage.getItem("vendorId")){
      this.vendorFlag=true;
      this.adminFlag=false;
    }
    else{
      this.vendorFlag=false;
      this.adminFlag=true;
    }
  }


  toggleSideBar() {
    this.toggleSideBarForMe.emit();
  }
  // toggleSideBarRight(){
  // this.toggleSideBarForMe.emit();

  // }

  logout() {
    this.router.navigateByUrl("/auth/login");
  }
  
  logout1() {
    this.router.navigateByUrl("/auth/vendorlogin");
  }

}
