import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { viewOrder, orderAssignDetails } from '../shared/AllModel';
import { acceptStatus, orderUpdateStatus, updateOrderStatus, updateOrderStatusURL } from '../shared/AllURL';
import { DataSharingService } from '../shared/service/data-sharing.service';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-assignorder',
  templateUrl: './assignorder.component.html',
  styleUrls: ['./assignorder.component.css']
})
export class AssignorderComponent implements OnInit {

  viewOrderModel = new viewOrder();
  orderAssignDetailsModel = new orderAssignDetails();
  orderStatus: string;
  showdivassign: boolean = false;
  showdivorder: boolean = false;
  orderAssignForm: FormGroup;


  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, public dataService: DataSharingService, private httpService: HttpmethodsService, private router: Router, ) {
  }

  ngOnInit() {
    this.orderAssignForm = this.formBuilder.group({
      orderNo: new FormControl(''),
    })
  }

  getAllAssignedOrders() {
 
    this.httpService.getRequest(updateOrderStatusURL + "/" + this.orderAssignDetailsModel.orderNo + "/" + "Accepted").subscribe((data: any) => {

      if(data){
        this.orderAssignDetailsModel = data;
        this.showdivorder = true;
      }
   
      else 
      {
        this.toastr.errorToastr('No Data Found!', 'Error!', { timeout: 500 }); 
        this.showdivorder = false;
      }

    })
  }

  packedOrderDetails(assignOrderModel) {
    this.orderAssignDetailsModel.customerId = assignOrderModel.customerId;
    this.orderAssignDetailsModel.customerMailId = assignOrderModel.customerMailId;
    this.orderAssignDetailsModel.customerMobNo = assignOrderModel.customerMobNo;
    this.orderAssignDetailsModel.orderId = assignOrderModel.orderId;
    this.orderAssignDetailsModel.orderStatus = "Packed";
    this.httpService.putRequest(acceptStatus, this.orderAssignDetailsModel).subscribe((data: any) => {
    this.toastr.successToastr('Order Packed Successfully...!', 'Success!', { timeout: 500 });
    this.router.navigateByUrl("/layout/dispatchorder")
    this.showdivassign = true;

    });
  }

}
