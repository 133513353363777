import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DeliveryChargesMaster } from '../shared/AllModel';
import { createPinCodeChargesMasterURL, updatePinCodeChargesMasterURL, getAllPinCodeChargesMasterURL, getByPinCodeChargesMasterURL, getActivePincodeMasterURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-pincodechargesmaster',
  templateUrl: './pincodechargesmaster.component.html',
  styleUrls: ['./pincodechargesmaster.component.css']
})
export class PincodechargesmasterComponent implements OnInit {

  DeliveryChargesMasterForm: FormGroup;
  DeliveryChargesMasterModel = new DeliveryChargesMaster();
  isEdit: boolean = true;
  hide = true;
  mobNumberMsg :string;
  ActivePincodeList:any;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.','pincode','code','status','Action', ];
  dataSource = new MatTableDataSource<DeliveryChargesMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.DeliveryChargesMasterForm = this.formBuilder.group({
      charges: new FormControl('', [Validators.required,]),
      pincodeId: new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),

    
    })
    this.getAllDeliveryCharges();
    this.getActiveList();
  }

  getActiveList(){
    this.httpService.getRequest(getActivePincodeMasterURL).subscribe((data:any)=>{
      this.ActivePincodeList=data;
    })
  }

  

  SaveDeliveryCharges() {
    if (this.isEdit) {
        this.httpService.postRequest(createPinCodeChargesMasterURL, this.DeliveryChargesMasterModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
            this.getAllDeliveryCharges();
            this.DeliveryChargesMasterForm.reset();
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
    }
    else {
      this.httpService.putRequest(updatePinCodeChargesMasterURL, this.DeliveryChargesMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllDeliveryCharges();
          this.DeliveryChargesMasterForm.reset();
          this.DeliveryChargesMasterModel = new DeliveryChargesMaster();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  getAllDeliveryCharges() {
    this.httpService.getRequest(getAllPinCodeChargesMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  getDeliveryChargesById(pinCodeChargesId) {
    this.httpService.getRequest(getByPinCodeChargesMasterURL + "/" + pinCodeChargesId).subscribe((data: any) => {
      this.DeliveryChargesMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
