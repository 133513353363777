import { Component } from '@angular/core';
// import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'AdminDashboardUI';
  constructor(){}

  // ShowSuccess(){
  //   this.toastr.success("Successfully Worked..!!");private toastr: ToastrService
  // }

  
  ngOnInit(){
    
    // setTimeout(function () {
		// 	$('.loader_bg').fadeToggle();
		// }, 3500);
  }

  onActive() {
    window.scroll(0, 0)
  }
}
