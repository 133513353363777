export class Product {
    descriptions: string;
    name: string;
    photoLink: string;
    productId: number;
    status: string;
    categoryId: number;
}





//////Admin master///
export class AdminMaster{
    address:string;
    adminId:number;
    adminName:string;
    emailId:string;
    mobileNo:string;
    password:string;
    status:string
}

//////admin login///
export class AdminLogin{
    password:string;
    userName:string;
}

///country master////
export class CountryMaster{
    countryName:string;
    countryNumber:string;
    countryStatus:string;
    countryId:number;
}

///state master///
export class StateMaster{
    countryId:number;
    stateId:number;
    stateName:string;
    status:string;
}

///city master///
export class CityMaster{
    cityId:number;
    cityName:string;
    cityStatus:string;
    stateId:number;
    countryId:number;
    cityCode:number;
}

////area master///
export class AreaMaster{
    areaId:number;
    areaName:string;
    areaStatus:string;
    cityId:number;
    stateId:number;
    countryId:number;
}

////pincode master///
export class PincodeMaster{
    areaId:number;
    pincodeCode:string;
    pincodeId:number;
    status:string;
    cityId:number;
    stateId:number;
    countryId:number;   
}

////category master///
export class CategoryMaster{
    categoryId:number;
    categoryImage:string;
    categoryName:string;
    categoryStatus:string;
    description:string;
    gst:number;
}

////product master//
export class ProductMaster{
    categoryId:number;
    discountAmount:number;
    discountPercentage:number;
    image1:string;
    image2:string;
    image3:string;
    mrp:number;
    price:number;
    productDescription:string;
    productId:number;
    productName:string;
    status:string;
    gstPercentage:number;
    weight:string;
}

export class VendorMaster{
    adminId:number;
    otp:number;
    password:string;
    pincodeId:number;
    vendorAadharCard:string;
    vendorEmail:string;
    vendorFirstName:string;
    vendorId:number;
    vendorLastName:string;
    vendorMiddleName:string;
    vendorMobile:string;
    vendorPAN:string;
    vendorPhoto:string;
    vendorRegDate:Date;
    vendorStatus:string;
    communicationAddress:string;
    permanentAddress:string;
    cityId:number;
    stateId:number;
    countryId:number;   
    areaId:number;
}

////banner master///
export class BannerMaster{
    bannerDescription:string;
    bannerId:number;
    bannerName:string;
    bannerPath:string;
    bannerType:string;
    endDate:string;
    startDate:string;
    status:string;
}

//customer login//
export class CustomerLogin{
    password:string;
    userName:string;
}

///customer master///
export class CustomerMaster{
    customerId:number;
    customerMailId:string;
    customerMobNo:string;
    customerName:string;
    otp:number;
    password:string;
    status:string;
    confirmCustomerPassword:string;
}

export class VerifyOtp{
    otp:number;
    customerId:number;
}

/////wishlist//
export class wishList{
    customerId! : number;
    productId! : number;

}

///add to cart/////
export class addToCartMaster{
    customerId!: number;
    productId!: number;
    cartDate!:Date;
    cartFinalPrice!:number;
    cartId!:number;
    cartPrice!:number;
    cartQty!:number;
    cartStatus!:string;
    cartTotalAmount!:number;

}

export class flagClass
{
    buttonFlag!: boolean;
}

export class customerOrder{
    cartResDtoList!:Array<CartProduct>;
    customerId!:number;
    customerName!:string;
    deliveryCharge!:number;
    email!:string;
    mobileNumber!:string;
    orderBillingAddress!:string;
    orderDate!:Date;
    orderDeliveryAddress!:string;
    orderDiscountAmount!:number;
    orderFinalAmount!:number;
    orderId!:number;
    orderPaymentMode!:string;
    orderStatus!:string;
    orderTaxAmount!:number;
    orderTotalAmount!:number;
    orderTotalGst!:number;
    orderTotalCartAmount!:number;
    tempOrderAmount!:number;
    deliveryCharges:number
    cityCode:number;
    totalAmount!:number;
    pincodeCode:number;
    FinalAmount:number;

}

export class CartProduct{
    cartId!:number;
    cartQty!:string;
    cartPrice!:number;
    cartTotalAmount!:number;
    productId!:number;
    productName!:string;
    categoryId!:number;
    categoryName!:string;
    image1!:string;
    wishListStatus:boolean=false;;
    wishListId!:number;
}

export class customerAddressList{
    addressFullAddress: any;
    addressHouseNo: any;
    addressId: any;
    addressLandmark: any;
    addressStatus: any;
    addressType: any;
    buildingName: any;
    companyId: any;
    createdAt: any;
    fullName: any;
    mobileNumber: any;
    pincodeId: any;
    updatedAt: any;
    areaId: any;
    stateId: any;
    cityId: any;
    areaName: any;
    stateName: any;
    pincodeCode: any;
    cityName: any; 
    cityCode:any;
}


export class paymentForm {
    amount!: string;
    customerId!: number;
    customerMailId!: string;
    customerMobNo!: string;
    customerName!: string;
  }

  export class AddCustomerAddress {
    addressFullAddress: any;
    addressHouseNo: any;
    addressId: any;
    addressLandmark: any;
    addressStatus: any;
    addressType: any;
    buildingName: any;
    customerId: any;
    createdAt: any;
    fullName: any;
    mobileNumber: any;
    pincodeId: any;
    updatedAt: any;
    areaId: any;
    stateId: any;
    cityId: any;
    countryId:any;
    areaName: any;
    stateName: any;
    countryName:any;
    pincodeCode: any;
    cityName: any;
    cityCode:any;
    statemaster : StateMaster;
    citymaster : CityMaster;
    areamaster : AreaMaster;
    pincodemaster : PincodeMaster;
    countrymaster:CountryMaster;

}

export class UpdateOrderStatus
{
    orderId!:number;
    orderStatus!:string;
    customerId!:number;
    customerMailId!:string;
    customerMobNo!:string;
    orderNo!:number;
    customerName!:string;
}
export class OrderTrack {
    orderTrackId!: number;
    orderTrackStatus!: string;
    date: any;
    orderId!: number;
    firstName: any;
    mobileNo: any;
}

export class OrderInvoiceJasperResDto
{
    orderId!:number;
}

export class orderAssignDetails {
    orderId:number;
    orderStatus:string;
    customerId:number;
    customerMailId:string;
    customerMobNo:string;
    orderNo:string;
    customerName:string;
    orderDate:Date;
    orderBillingAddress:string;
    orderPaymentMode:string;
    productQty:string;
    orderTotalCartAmount:number;
    orderDiscountAmount:number;
    deliveryCharges:number;
    orderFinalAmount:number;
}

export class AssignOrder {
    orderId: number;
    vendorId: number;
  }
  
  export class viewOrder {
    orderId: number;
    orderBillingAddress: string;
    orderDeliveryAddress: string;
    orderTotalCartAmount: number;
    orderCouponCode: string;
    orderCouponAmount: string;
    orderFinalAmount: number;
    orderStatus: string;
    reason: string;
    orderDate: string;
    orderPaymentMode: string;
    customerId: number;
    customerFName: string;
    customerMName: string;
    customerLName: string;
    customerMail: string;
    customerMobileNo: number;
    customerMaster: any;
    orderMaster: any;
    vendorMaster: any;
    firstName:string;
    lastName:string;
    mobileNo:string;
    walletFlag:boolean;
}

export class VendorLogin{
    password:string;
    userName:string;
}

export class ordersDetails {
    orderId: number;
    orderBillingAddress: string;
    orderDeliveryAddress: string;
    orderTotalCartAmount: number;
    orderFinalAmount: number;
    orderStatus: string;
    orderDate: string;
    orderPaymentMode: string;
    customerId: number;
    customerFName: string;
    customerMName: string;
    customerLName: string;
    customerMail: string;
    customerMobileNo: number;
}


export class DeliveryBoyMaster{
    adharCard:string;
    deliveryBoyId:number;
    drivingLiscence:string;
    emailId:string;
    firstName:string;
    lastName:string;
    middleName:string;
    mobileNo:string;
    panCard:string;
    password:string;
    pincodeId:number;
    pincodeIdList:Array<number>;
    profilePic:string;
    registrationDate:Date;
    status:string;
    adminId:number;
}

export class DeliveryChargesMaster{
    charges:number;
    pinCodeChargesId:number;
    pincodeId:number;
    status:string;
}

export class AboutMaster{
    aboutUsDescription:string;
    aboutUsId:number;
    missionDescription:string;
    status:string;
    visionDescription:string;
}

export class CustomerOrderReportClass
{
  orderStatus: string;
}

export class UpdatePassword{
    customerId:number;
    confirmPassword:string;
    newPassword:string;
    oldPassword:string;
}
  export class tempPassword{
    customerId:number;
    password:string;
}

export class ReviewsMaster {
    customerId: number;
    customerReviewId: number;
    feedback: string;
    productId: number;
    productImg: string;
    reviewStar: number;
    reviewImagesReqDtoList: Array<ReviewImgClass>;

}

export class ReviewImgClass {
    customerReviewId: number;
    imgLink: string;
    reviewImagesId: number;
    status: string;
}

export class MessageMaster{
    message:string;
    messageId:number;
    status:string;
}

export class ContactMaster{
    contactId:number;
    contactNo:string;
    emailId:string;
    message:string;
    name:string;
    subject:string;
}