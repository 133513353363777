import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ordersDetails } from '../shared/AllModel';
import { orderHistoryURL } from '../shared/AllURL';
import { DataSharingService } from '../shared/service/data-sharing.service';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-orderhistory',
  templateUrl: './orderhistory.component.html',
  styleUrls: ['./orderhistory.component.css']
})
export class OrderhistoryComponent implements OnInit {

  customerName: string;
  orderHistoryForm: FormGroup;
  orderDetailsModel = new ordersDetails();
  orderStatus: string;
  errormsgDiv: boolean = false;
  errormsg: string;


  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, public dataService: DataSharingService, private httpService: HttpmethodsService, private router: Router) { }

  displayedColumns: string[] = ['Sr.No.', 'Order No', 'Date', 'Customer Name', 'Mobile No', 'Amount', 'Status', 'Action'];
  dataSource = new MatTableDataSource<ordersDetails>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.getAllOrders();
    this.orderHistoryForm = this.formBuilder.group({
      orderStatus: new FormControl('')
    })
    this.dataSource.sort = this.sort;
  }

  
  getAllOrders() {
    this.httpService.getRequest(orderHistoryURL + "/" + "Assigned" + "/vendor/" + sessionStorage.getItem("vendorId")).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
    })
  }

  viewOrder(order) {
    this.dataService.data = order;
    sessionStorage.setItem("orderId", order);

    this.router.navigateByUrl("/layout/vieworder")
  }

}
