import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssignOrder, orderAssignDetails, viewOrder } from '../shared/AllModel';
import { acceptStatus, ActiveVendorURL, AssignOrderURL, ViewOrderByOrderIdURL } from '../shared/AllURL';
import { DataSharingService } from '../shared/service/data-sharing.service';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-vieworderplaced',
  templateUrl: './vieworderplaced.component.html',
  styleUrls: ['./vieworderplaced.component.css']
})
export class VieworderplacedComponent implements OnInit {

  ViewCustomerOrderList:any[]=[];
  orderList:any[] = [];
  productList:any[] = [];
  avtiveVendorList: any = [];
  orderAssignDetailsModel = new orderAssignDetails();
  spinnerService: any;
  viewPlacedOrderForm: FormGroup;
  assignOrderModel = new AssignOrder();
  viewOrderModel = new viewOrder();

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, public dataService: DataSharingService, private httpService: HttpmethodsService, private router: Router,private spinner: NgxSpinnerService ) {
  }

  ngOnInit() {
    this.viewPlacedOrderForm = this.formBuilder.group({
      vendorId: new FormControl('', Validators.required)
    })
    this.ViewOrder();
    this.getActiveVendor();
  }

  getActiveVendor() {
    this.httpService.getRequest(ActiveVendorURL + "/" + 1).subscribe((data: any) => {
      this.avtiveVendorList = data; 
    })
  }

  ViewOrder(){
    this.httpService.getRequest(ViewOrderByOrderIdURL + "/" + Number(sessionStorage.getItem("orderId"))).subscribe((data:any)=>{
      this.ViewCustomerOrderList.push(data);
      this.viewOrderModel = data;
      this.orderList.push(this.ViewCustomerOrderList[0].orderMaster);
      this.productList = this.ViewCustomerOrderList[0].productMasterList;
      console.log(this.ViewCustomerOrderList[0]);
    })
  }



  assignVendor(assignOrderModel) {
    // this.spinnerService.show();
    this.assignOrderModel.orderId = this.viewOrderModel.orderMaster.orderId;
    this.assignOrderModel.vendorId = assignOrderModel.vendorId; 
    this.httpService.putRequest(AssignOrderURL, this.assignOrderModel).subscribe((data: any) => { 
      this.toastr.successToastr('Assigned Successfully...!', 'Success!', {timeout : 500}); 
    // this.spinnerService.hide();
      this.router.navigateByUrl("/layout/placeorder");
    })
  }

}
