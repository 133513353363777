import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CityMaster, CountryMaster, StateMaster } from '../shared/AllModel';
import { createCityMasterURL, getActiveCountryMasterURL, getAllCityMasterURL, getByCityMasterIdURL, getCountryWiseStateActiveListURL, updateCityMasterURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-citymaster',
  templateUrl: './citymaster.component.html',
  styleUrls: ['./citymaster.component.css']
})
export class CitymasterComponent implements OnInit {
  CityMasterForm: FormGroup;
  CityMasterModel = new CityMaster();
  isEdit: boolean = true;
  hide = true;
  mobNumberMsg :string;
  ActiveCountryList:Array<CountryMaster>;
  ActiveStateList:Array<StateMaster >;


  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'name','code','status','Action' ];
  dataSource = new MatTableDataSource<CityMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.CityMasterForm = this.formBuilder.group({
      cityName: new FormControl('', [Validators.required,Validators.pattern("[a-zA-Z][a-zA-Z ]+")]),
      status: new FormControl('', [Validators.required,]),
      stateId: new FormControl('', [Validators.required,]),
      countryId:new FormControl('', [Validators.required,]),
      cityCode:new FormControl('', [Validators.required,]),

    })
    this.ActiveCountry();
    this.getAllCity();
  }
  ActiveCountry(){
    this.httpService.getRequest(getActiveCountryMasterURL).subscribe((data: any) => {
     this.ActiveCountryList=data;
    })
  }

  ActiveState(e:any){
    
    this.httpService.getRequest(getCountryWiseStateActiveListURL + "/" + e.value).subscribe((data: any) => {
      this.ActiveStateList=data;
     })
  }
  
  SaveCity() {
    if (this.isEdit) {
        this.httpService.postRequest(createCityMasterURL, this.CityMasterModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
            this.getAllCity();
            this.CityMasterForm.reset();
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
    }
    else {
      this.httpService.putRequest(updateCityMasterURL, this.CityMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllCity();
          this.CityMasterForm.reset();
          this.CityMasterModel = new CityMaster();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  getAllCity() {
    this.httpService.getRequest(getAllCityMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  getCityById(cityId) {
    this.httpService.getRequest(getByCityMasterIdURL + "/" + cityId).subscribe((data: any) => {
      this.CityMasterModel = data;
      this.CityMasterModel.countryId = data.stateMaster.countryMaster.countryId; 
      this.CityMasterModel.stateId = data.stateMaster.stateId;
      this.getByCityIdActiveState(this.CityMasterModel)
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    
    })
  }

  getByCityIdActiveState(e:any){
    
    this.httpService.getRequest(getCountryWiseStateActiveListURL + "/" + e.countryId).subscribe((data: any) => {
      this.ActiveStateList=data;
     })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
