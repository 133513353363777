import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { StateMaster } from '../shared/AllModel';
import { createStateMasterURL, updateStateMasterURL, getAllStateMasterURL, getByIdStateMasterURL, getCountryWiseStateActiveListURL, getActiveCountryMasterURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-statemaster',
  templateUrl: './statemaster.component.html',
  styleUrls: ['./statemaster.component.css']
})
export class StatemasterComponent implements OnInit {

  StateMasterForm: FormGroup;
  StateMasterModel = new StateMaster();
  isEdit: boolean = true;
  hide = true;
  mobNumberMsg :string;
  ActiveCountryList:any;

  
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'name','status','Action', ];
  dataSource = new MatTableDataSource<StateMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.StateMasterForm = this.formBuilder.group({
      stateName: new FormControl('', [Validators.required,Validators.pattern("[a-zA-Z][a-zA-Z ]+")]),
      status: new FormControl('', [Validators.required,]),
      countryId: new FormControl('', [Validators.required,]),
    
    })
    this.getAllState();
    this.ActiveCountry();
  }

  ActiveCountry(){
    this.httpService.getRequest(getActiveCountryMasterURL).subscribe((data: any) => {
     this.ActiveCountryList=data;
    })
  }
  

  SaveState() {
    if (this.isEdit) {
        this.httpService.postRequest(createStateMasterURL, this.StateMasterModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
            this.getAllState();
            this.StateMasterForm.reset();
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
    }
    else {
      this.httpService.putRequest(updateStateMasterURL, this.StateMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllState();
          this.StateMasterForm.reset();
          this.StateMasterModel = new StateMaster();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  getAllState() {
    this.httpService.getRequest(getAllStateMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  getStateById(stateId) {
    this.httpService.getRequest(getByIdStateMasterURL + "/" + stateId).subscribe((data: any) => {
      this.StateMasterModel = data;
      this.StateMasterModel.countryId=data.countryMaster.countryId;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
