import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminmasterComponent } from './adminmaster/adminmaster.component';
import { CountrymasterComponent } from './countrymaster/countrymaster.component';
import { StatemasterComponent } from './statemaster/statemaster.component';
import { CitymasterComponent } from './citymaster/citymaster.component';
import { AreamasterComponent } from './areamaster/areamaster.component';
import { PincodemasterComponent } from './pincodemaster/pincodemaster.component';
import { CategorymasterComponent } from './categorymaster/categorymaster.component';
import { ProductmasterComponent } from './productmaster/productmaster.component';
import { VendormasterComponent } from './vendormaster/vendormaster.component';
import { BannermasterComponent } from './bannermaster/bannermaster.component';
import { PlaceorderComponent } from './placeorder/placeorder.component';
import { VieworderplacedComponent } from './vieworderplaced/vieworderplaced.component';
import { PlacedorderlistComponent } from './placedorderlist/placedorderlist.component';
import { OrderhistoryComponent } from './orderhistory/orderhistory.component';
import { VieworderComponent } from './vieworder/vieworder.component';
import { DeliverymasterComponent } from './deliverymaster/deliverymaster.component';
import { PincodechargesmasterComponent } from './pincodechargesmaster/pincodechargesmaster.component';
import { AboutmasterComponent } from './aboutmaster/aboutmaster.component';
import { AssignorderComponent } from './assignorder/assignorder.component';
import { AllcustomerlistComponent } from './allcustomerlist/allcustomerlist.component';
import { OrderlistComponent } from './orderlist/orderlist.component';
import { DispatchorderComponent } from './dispatchorder/dispatchorder.component';
import { DeliverdorderComponent } from './deliverdorder/deliverdorder.component';
import { DailyreportComponent } from './dailyreport/dailyreport.component';
import { WeeklyorderreportComponent } from './weeklyorderreport/weeklyorderreport.component';
import { MonthlyorderreportComponent } from './monthlyorderreport/monthlyorderreport.component';
import { YearlyorderreportComponent } from './yearlyorderreport/yearlyorderreport.component';
import { MessagemasterComponent } from './messagemaster/messagemaster.component';


const routes: Routes = [
  { path: '', pathMatch: "full", redirectTo: 'pages/home' },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(mod => mod.AuthModule) },
  { path: 'pages', loadChildren: () => import('./pages/pages.module').then(mod => mod.PagesModule) },

  {
    path: 'layout', component: LayoutComponent,
    children: [
      {path: '', component:DashboardComponent},
      {path:'adminmaster',component:AdminmasterComponent},
      {path:'countrymaster',component:CountrymasterComponent},
      {path:'statemaster',component:StatemasterComponent},
      {path:'citymaster',component:CitymasterComponent},
      {path:'areamaster',component:AreamasterComponent},
      {path:'pincodemaster',component:PincodemasterComponent},
      {path:'categorymaster',component:CategorymasterComponent},
      {path:'productmaster',component:ProductmasterComponent},
      {path:'vendormaster',component:VendormasterComponent},
      {path:'bannermaster',component:BannermasterComponent},
      {path:'placeorder',component:PlaceorderComponent},
      {path:'vieworderplaced',component:VieworderplacedComponent},
      {path:'placedorderlist',component:PlacedorderlistComponent},
      {path:'orderhistory',component:OrderhistoryComponent},
      {path:'vieworder',component:VieworderComponent},
      {path:'deliverymaster',component:DeliverymasterComponent},
      {path:'pincodechargesmaster',component:PincodechargesmasterComponent},
      {path:'aboutmaster',component:AboutmasterComponent},
      {path:'assignorder',component:AssignorderComponent},
      {path:'allcustomerlist',component:AllcustomerlistComponent},
      {path:'orderlist',component:OrderlistComponent},
      {path:'dispatchorder',component:DispatchorderComponent},
      {path:'deliverdorder',component:DeliverdorderComponent},
      {path:'dailyreport',component:DailyreportComponent},
      {path:'weeklyorderreport',component:WeeklyorderreportComponent},
      {path:'monthlyorderreport',component:MonthlyorderreportComponent},
      {path:'yearlyorderreport',component:YearlyorderreportComponent},
      {path:'messagemaster',component:MessagemasterComponent},

 
   
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
