import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ProductMaster } from '../shared/AllModel';
import { createProductURL, fileUpload, getActiveCategoryMasterURL, getAllProductURL, getByProductURL, updateProductURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-productmaster',
  templateUrl: './productmaster.component.html',
  styleUrls: ['./productmaster.component.css']
})
export class ProductmasterComponent implements OnInit {

  productModel = new ProductMaster()
  filetoUpload: File ;
  catList:any;
 
  isEdit: boolean = true;

  productForm: FormGroup;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
 
 
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'name','img','price','status','Action', ];
  dataSource = new MatTableDataSource<ProductMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.productForm = this.formBuilder.group({
      categoryId: new FormControl('', ),
      productName: new FormControl('', ),
      mrp: new FormControl('', ),
      weight:new FormControl('', ),
      discountPercentage: new FormControl('', ),
      discountAmount: new FormControl('', [Validators.required]),
      price: new FormControl('', [Validators.required]),
      image1: new FormControl('', [Validators.required]),
      image2: new FormControl('',),
      image3: new FormControl('', ),
      productDescription: new FormControl('', ),
      status: new FormControl('', ),
      productNameEng:new FormControl('', [Validators.required]),


    });
    this.getCategoryList();
    this.getProductMaster();
  }

  getCategoryList() {
    this.httpService.getRequest(getActiveCategoryMasterURL).subscribe((data:any) => {
      this.catList = data
    })
  }

  discountAmountCalculate() {
    if (this.productModel.discountPercentage >= 0) {
      this.productModel.discountAmount = this.productModel.mrp * this.productModel.discountPercentage / 100;
      this.productModel.price = this.productModel.mrp - this.productModel.discountAmount;
    }
    else {

    }
  }

  discountPercentageCalculate() {
    this.productModel.discountPercentage = this.productModel.discountAmount * 100 / this.productModel.mrp;
    this.productModel.price = this.productModel.mrp - this.productModel.discountAmount;
  }

  discountFinalCalculate() {
    if (this.productModel.price <= this.productModel.mrp) {
      this.productModel.discountAmount = this.productModel.mrp - this.productModel.price;
      this.productModel.discountPercentage = this.productModel.discountAmount * 100 / this.productModel.mrp;
    }
    else {

    }
  }

  
  saveProductMaster() {
    console.log(this.productModel);
    if(this.isEdit) {
      this.httpService.postRequest(createProductURL, this.productModel).subscribe((data:any) => {
      
        if(data) {
          this.toastr.successToastr("Successfully Added..", "Success")
          this.getProductMaster()
          this.productModel = new ProductMaster()
          this.isEdit = true

        } else {
          this.toastr.errorToastr("something went wrong", "Error")
        }
      })
    } else {
      this.httpService.postRequest(updateProductURL, this.productModel).subscribe((data:any) => {
      
        if(data) {
          this.toastr.successToastr("Successfully Updated..", "Success")
          this.getProductMaster()
          this.productModel = new ProductMaster()
          this.isEdit = true
 
        } else {
          this.toastr.errorToastr("something went wrong", "Error")
        }
      })
    }
    
  }

  handleFileInput(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.productModel.image1 = data.path;
      }
      else {
        this.toastr.errorToastr(data.image1, 'error!', { timeout: 500 });
      }
    })
  }
  handleFileInput1(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.productModel.image2 = data.path;
      }
      else {
        this.toastr.errorToastr(data.image2, 'error!', { timeout: 500 });
      }
    })
  }
  handleFileInput2(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.productModel.image3 = data.path;
      }
      else {
        this.toastr.errorToastr(data.image3, 'error!', { timeout: 500 });
      }
    })
  }
  getProductMaster() {
    this.httpService.getRequest(getAllProductURL).subscribe((data:any) => {
      console.log(data);
      this.dataSource = new MatTableDataSource(data)
      this.dataSource.paginator = this.paginator
    })
  }

  
  getProductById(productId) {
     
    this.httpService.getRequest(getByProductURL + "/" + productId).subscribe((data: any) => {
      this.productModel = data;
      
      this.isEdit = false; 
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }
}
